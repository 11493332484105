import PzBookCard from "components/pz/PzBookCard";
import { CustomerBookData } from "services/firestore/types";

import { getCoverpath } from "../../../services/firestore/hooks/useBookApi";
import CardDisplayer from "../common/CardDisplayer";

interface CatalogueDisplayerProps {
    cards: CustomerBookData[];
}

export default function BookcaseDisplayer({ cards }: CatalogueDisplayerProps) {
    function getBookSource(book: CustomerBookData) {
        return book.customerBook.bookId?.includes("bokbasen") ? "bokbasen" : "publizm";
    }
    return (
        <CardDisplayer>
            {cards.map((book, index) => {
                return (
                    <PzBookCard
                        author={book.customerBook.author?.name}
                        authorHighlighted={book.customerBook.author?.name}
                        prices={[]}
                        title={book.customerBook.title}
                        titleHighlighted={book.customerBook.title}
                        coverPath={getCoverpath({
                            coverPath: book.customerBook.coverPath,
                            bookId: book.customerBook.bookId,
                            bookType: book.customerBook.bookType,
                            size: "small",
                            source: book.customerBook.source ?? getBookSource(book),
                            updatedDate: book.customerBook.updatedDate?.toDate(),
                        })}
                        key={`${book.customerBook.bookId}-${index}`}
                        id={book.customerBook.bookId}
                    />
                );
            })}
        </CardDisplayer>
    );
}

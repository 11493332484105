import PzButton from "components/form/PzButton";
import PzTable from "components/table/PzTable";
import { RowData } from "components/table/TableBody";
import { ColumnData } from "components/table/TableHead";
import PzText from "components/text/PzText";
import { bookStateOptions } from "pages/dashboard/admin/book/bookadmin/BookAdminTypes";
import { useEffect, useState } from "react";
import { BookData } from "services/firestore/types";
import { UserService } from "services/firestore/UserService";
import { BookType, CustomerBook } from "shared";
import { BooksIndex } from "../../services/algolia/types";
import useBookApi from "../../services/firestore/hooks/useBookApi";

export default function GiveBookAccessTable(props: { books: BooksIndex[]; userId: string }): JSX.Element {
    const { books, userId } = props;
    const [userTransactionBookIds, setUserTransactionBookIds] = useState<string[]>([]);

    useEffect(() => {
        new UserService.Transaction(userId).getAll().then((transactions) => {
            const transactionIds = transactions
                .filter((transactionData) => transactionData.transaction.bookType === BookType.BUNDLE)
                .map((transactionData) => transactionData.transaction.bookId);
            setUserTransactionBookIds(transactionIds);
        });
    }, []);

    const addBook = (bookId: string) => {
        setUserTransactionBookIds((prevBookIds) => [...prevBookIds, bookId]);
    };

    const ownsBook = (bookData: BooksIndex, userTransactionBookIds: string[]) => {
        return userTransactionBookIds.indexOf(bookData.objectID) !== -1;
    };

    return books.length > 0 ? (
        <PzTable
            columns={columns}
            createRow={(bookData: BooksIndex) =>
                createRow(bookData, userId, addBook, ownsBook(bookData, userTransactionBookIds))
            }
            data={books}
            rowKeyGenerator={(data) => data.objectID}
            defaultSortIndex={0}
            enableExternalDataUpdate
        />
    ) : (
        <PzText>Ingen treff. Enten eksisterer ikke søkeordene i databasen, eller så er søketeksen tom.</PzText>
    );
}

function createRow(
    bookData: BooksIndex,
    userId: string,
    addBookToOwnedBooks: (bookId) => void,
    ownsBundle: boolean
): RowData {
    const mapStatusToNorwegian = (bookDataStatus) => {
        return bookDataStatus
            ? bookStateOptions[bookStateOptions.map((option) => option.value).indexOf(bookDataStatus)].label
            : "";
    };

    return {
        components: [
            {
                content: bookData.title,
                width: "30%",
            },
            {
                content: bookData["author.name"],
                width: "30%",
            },
            {
                content: mapStatusToNorwegian(bookData.status),
                width: "12.5%",
            },
            {
                content: ownsBundle ? "Ja" : "Nei",
                width: "12.5%",
            },
            {
                content: (
                    <BookAccessButton
                        bookData={bookData}
                        userId={userId}
                        disabled={ownsBundle}
                        addBookToOwnedBooks={addBookToOwnedBooks}
                    />
                ),
                width: "15%",
            },
        ],
    };
}

const columns: ColumnData[] = [
    {
        label: "Tittel",
        sortByProperty: "bookData.book.title",
    },
    {
        label: "Forfatter",
        sortByProperty: "bookData.book.author.name",
    },
    {
        label: "Status",
    },
    {
        label: "Eier bundle",
    },
    {
        label: "Gi tilgang",
    },
];

function BookAccessButton(props: {
    bookData: BooksIndex;
    userId: string;
    disabled: boolean;
    addBookToOwnedBooks: (bookId) => void;
}) {
    const bookId = props.bookData.objectID;
    const book = useBookApi().bookById(props.bookData.objectID).data!;
    const { userId, disabled, addBookToOwnedBooks } = props;
    const [isWaitingForBookAccess, setIsWaitingForBookAccess] = useState<boolean>(false);
    let customerBookDocument;
    const giveAccess = async () => {
        setIsWaitingForBookAccess(true);
        const transactionService = new UserService.Transaction(userId);
        const customerBookService = new UserService.CustomerBook(userId);
        const customerBook: CustomerBook = {
            title: book.title,
            bookId: bookId,
            purchased: true,
            bookType: BookType.BUNDLE,
            authorName: book.author.name,
            author: book.author,
            pages: book.ebook?.pages,
            summary: book.summary,
            type: BookType.BUNDLE,
            language: book.language,
            coverPath: book.coverPath,
            tags: book.tags,
            publisher: book.publisher,
        };

        if (await transactionService.hasPurchasedBook(bookId, BookType.BUNDLE)) {
            setIsWaitingForBookAccess(false);
            return Promise.resolve();
        } else if (await transactionService.hasPurchasedBook(bookId, BookType.EBOOK)) {
            customerBookDocument = await customerBookService.getByBookIdAndType(bookId, BookType.EBOOK);
        } else if (await transactionService.hasPurchasedBook(bookId, BookType.AUDIO)) {
            //TODO: Når vi har fikset opp i boktype-problemet i CustomerBook kan vi gå tilbake
            // til å bruke getByBookIdAndType her
            customerBookDocument = await customerBookService.getByBookId(bookId);
        }

        await transactionService.addTransaction(bookId, BookType.BUNDLE, 0);
        await new UserService.CustomerBook(userId).updateCustomerBook(customerBook, customerBookDocument?.documentId);
        addBookToOwnedBooks(bookId);
        setIsWaitingForBookAccess(false);
    };

    return (
        <PzButton onClick={giveAccess} isLoading={isWaitingForBookAccess} disabled={disabled}>
            Gi tilgang
        </PzButton>
    );
}

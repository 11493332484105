import PzButton from "components/form/PzButton";
import PzModal from "components/modal/PzModal";
import { SearchField } from "components/search/SearchField";
import { Column, Container, Help, Level, Modal } from "components/trunx";
import { useState } from "react";

import GiveBookAccessTable from "./GiveBookAccessTable";
import AlgoliaSearchProvider from "../algolia/AlgoliaSearchProvider";
import { Configure, useInstantSearch, useSearchBox } from "react-instantsearch-core";

interface GiveBookAccessButtonProps {
    userUid: string;
}

export default function GiveBookAccessButton({ userUid }: GiveBookAccessButtonProps): JSX.Element {
    const [giveBookAccessModalOpen, setGiveBookAccessModalOpen] = useState<boolean>(false);

    const onOpen = () => setGiveBookAccessModalOpen(true);
    function createAlgoliaFilterQuery() {
        let filter = `source: publizm`;


        return filter;
    }

    return (
        <>
            <AlgoliaSearchProvider index={import.meta.env.VITE_ALGOLIA_INDEX_BOOKS!}>
                <Configure filters={createAlgoliaFilterQuery()} />
                <LesetilgangModal open={giveBookAccessModalOpen} setGiveBookAccessModalOpen={setGiveBookAccessModalOpen} userUid={userUid} />
            </AlgoliaSearchProvider>
            <PzButton mr2 onClick={onOpen}>
                Gi lesetilgang til bok
            </PzButton>
        </>
    );
}


function LesetilgangModal({ open, setGiveBookAccessModalOpen, userUid }: {
    open: boolean;
    setGiveBookAccessModalOpen: (open: boolean) => void;
    userUid: string;
}) {
    const [errorMessage, setErrorMessage] = useState<string>();
    const { results, status } = useInstantSearch();
    const { refine: refineSearch } = useSearchBox();

    const onClose = () => {
        setErrorMessage(undefined);
        setGiveBookAccessModalOpen(false);
    };

    const onChange = (input: string) => refineSearch(input.toLowerCase().trim());


    return <PzModal isActive={open} onClose={onClose}>
        <Modal.Card.Head>
            <Level>
                <Level.Left>
                    <Level.Item>
                        <Modal.Card.Title>Gi lesetilgang til bok</Modal.Card.Title>
                    </Level.Item>
                </Level.Left>
            </Level>
        </Modal.Card.Head>
        <Modal.Card.Body style={{ width: "850px" }}>
            <Container isFlex isFlexDirectionColumn>
                <Column isFlex isAlignSelfFlexEnd isFlexDirectionRow>
                    <SearchField onChange={onChange} />
                </Column>
                <GiveBookAccessTable books={results.hits ?? []} userId={userUid} />
                {errorMessage && (
                    <Column>
                        <Help isDanger>{errorMessage}</Help>
                    </Column>
                )}
                <Column>
                    <PzButton isNormal onClick={onClose}>
                        Lukk
                    </PzButton>
                </Column>
            </Container>
        </Modal.Card.Body>
    </PzModal>
}